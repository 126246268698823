<template>
  <div class="container">
    <div class="block">
      <el-carousel height="500px"
                   trigger="click">
        <el-carousel-item>
          <img :src="$store.state.isZH ? MbnanerZH : MbnanerEN"
               class="img"
               v-if="isShow"
               alt="">
          <img :src="$store.state.isZH ? ZHbanner : ENbanner"
               v-else
               class="img"
               alt="">

        </el-carousel-item>
      </el-carousel>
      <el-row type="flex"
              id="banner-bottom">
        <el-col :xs="1"
                :sm="1"
                :md="2"
                :lg="2"
                :xl="3">
        </el-col>
        <el-col :xs="8"
                :sm="6"
                :md="5"
                :lg="5"
                :xl="4"
                id="col-flex">
          <div class="flex"
               @click="bannerClick(1)">
            <div class="banner-title"
                 :class="$store.state.isZH ? '' : 'banner-text'">{{$t('index.index.carouselL')}}</div>
            <img src="../assets/zhuye/pigIcon.png"
                 class="banner-icon"
                 id="left-icon"
                 alt="">

          </div>
        </el-col>
        <el-col :xs="0"
                :sm="2"
                :md="3"
                :lg="3"
                :xl="4">
        </el-col>
        <el-col :xs="8"
                :sm="6"
                :md="5"
                :lg="5"
                :xl="4"
                id="col-flex">
          <div class="flex"
               @click="bannerClick(2)">
            <div class="banner-title"
                 :class="$store.state.isZH ? '' : 'banner-text'">{{ $t('index.index.carouselC') }}</div>
            <img src="../assets/zhuye/UserIcon.png"
                 class="banner-icon"
                 id="center-icon"
                 alt="">
          </div>
        </el-col>
        <el-col :xs="0"
                :sm="2"
                :md="3"
                :lg="3"
                :xl="4">
        </el-col>
        <el-col :xs="5"
                :sm="5"
                :md="5"
                :lg="5"
                :xl="3"
                id="col-flex">
          <div class="flex"
               @click="bannerClick(3)">
            <div class="banner-title"
                 :class="$store.state.isZH ? '' : 'banner-text'">{{ $t('index.index.carouselR') }}</div>
            <img src="../assets/zhuye/yaoIcon.png"
                 class="banner-icon"
                 id="right-icon"
                 alt="">
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="content">
      <div class="news">
        <div class="content-title">
          <h2 class="title-item">{{ $t('index.index.news') }}</h2>
          <img src="~@/assets/zhuye/news.png"
               alt="">
        </div>
        <div class="news-content font-color newsUrl"
             style="display: flex; flex-direction: column;justify-content: center;">
          <div style="padding:0 20px; display: flex;flex-direction: column;justify-content: center;">
            <h3 class=" h3-size">{{ $t('index.index.newsValue') }}</h3>
            <h3 class=" h3-size">{{ $t('index.index.newsValue2') }}</h3>
            <span class="news-span">{{ $t('index.index.newsValue3') }}</span>
          </div>
        </div>
      </div>

      <div class="now-future"
           v-if="$store.state.isZH">
        <div class="content-title">
          <h2 class="title-item">现在与未来</h2>
          <img src="~@/assets/zhuye/eyes.png"
               alt="">
        </div>

        <div class="now-content">
          <div class="now-left aboutUrl"
               id="left"></div>
          <div class="now-right"
               id="right">
            <h3 class="h3-size"
                style="font-weight: revert;">关于我们</h3>
            <span class="now-span">厦门欧瑞捷生物科技有限公司，坐落于厦门市火炬高新区翔安产业区，是一家集人用药和兽用药研发、生产与销售为一体的国家级高新技术企业。
            </span>
            <div class="detailClick"
                 @click="detailClick('1')"><img src="~@/assets/zhuye/lArrow.png"
                   style="width: 12px;">
              <span class="detail">了解详情</span>
            </div>
          </div>
        </div>

        <div class="now-content">
          <div class="now-right"
               id="right">
            <h3 class="h3-size"
                style="font-weight: revert;">发展历程</h3>
            <span class="now-span">业精于勤，业精于专！在专业专注、精益求精的道路上，欧瑞捷在奔跑！
            </span>
            <div class="detailClick"
                 @click="detailClick('2')"><img src="~@/assets/zhuye/rArrow.png"
                   style="width: 12px;"
                   alt="">
              <span class="detail">了解详情</span>
            </div>
          </div>
          <div class="now-left HistoryUrl"
               id="left"></div>
        </div>

        <div class="now-content">
          <div class="now-left qiyeUrl"
               id="left"></div>
          <div class="now-right "
               id="right">
            <h3 class="h3-size"
                style="font-weight: revert;">企业荣誉</h3>
            <span class="now-span">作为国家级高新技术企业，欧瑞捷先后被评为厦门市“双百人才企业”、省科技小巨人领军企业、创新性试点企业，以及科技型中小企业等。
            </span>
            <div class="detailClick"
                 @click="detailClick('3')"><img src="~@/assets/zhuye/lArrow.png"
                   style="width: 12px;"
                   alt="">
              <span class="detail">了解详情</span>
            </div>
          </div>
        </div>

        <div class="now-content">
          <div class="now-right"
               id="right">
            <h3 class="h3-size"
                style="font-weight: revert;">售后服务</h3>
            <span class="now-span">欧瑞捷致力于国内外市场的开拓。凭借卓越的产品质量、注册技术支持及销售服务，公司产品销售量稳居行业前列。
            </span>
            <div class="detailClick"
                 @click="detailClick('4')"><img src="~@/assets/zhuye/rArrow.png"
                   style="width: 12px;"
                   alt="">
              <span class="detail">了解详情</span>
            </div>
          </div>
          <div class="now-left salesUrl"
               id="left"></div>
        </div>
        <div class="news"
             id="bottom-n">
          <div class="content-title">
            <h2 class="title-item">公益与责任</h2>
            <img src="~@/assets/zhuye/homeIcon.png"
                 alt="">
          </div>
          <div class="news-content font-color aixinUrl"
               id="love"
               style="display: flex; flex-direction: column;justify-content: center; ">
            <div style="padding:0 20px; display: flex;flex-direction: column;justify-content: center; align-items: flex-end;">
              <h3 class=" h3-size">健康科技 温暖更多生命</h3>
              <span class="news-span">与各方携手抗疫、并肩同行、传递温暖、守护健康</span>
              <div class="detailClick"
                   @click="detailClick('5')"
                   style="align-self: end;">
                <span class="detail">了解详情</span>
                <img src="~@/assets/Rjiantou.png"
                     style="width: 22px; vertical-align: middle;"
                     alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 英文 -->
      <div class="news"
           v-if="!$store.state.isZH">
        <div class="content-title">
          <h2 class="title-item">This is Origin</h2>
          <img src="~@/assets/EN/iconM.png"
               style="width: 30px;"
               alt="">
        </div>
        <div class="news-content font-color enBgc">
          <div class="ENwidth">
            <img src="../assets/M/MLogo.png"
                 class="icon"
                 alt="">
            <span class="news-span">Xiamen Origin Biotech Co., Ltd, located in Xiamen Torch Hi-tech Industrial</span>
            <span class="news-span">Development Zone. It's a 'State Hi- tech Enterprise' integrating R&D,</span>
            <span class="news-span">manufacturing, and sales of human and veterinary drugs. Since its establishment in 2013, Xiamen Origin has been </span>
            <span class="news-span">awarded multiple honors from different authorities.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      ZHbanner: require('../assets/zhuye/banner.png'),
      ENbanner: require('../assets/zhuye/ENbanner.png'),
      MbnanerEN: require('../assets/M/MBanner.png'),
      MbnanerZH: require('../assets/M/MBannerZH.png'),
      // Maixin: require('../assets/zhuye/M.png'),
      aixin: require('../assets/zhuye/aixin.png'),
      isShow: false,
      fullWidth: null
    }
  },
  created () {
    this.fullWidth = document.documentElement.clientWidth
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    detailClick (val) {
      // 1.关于我们 2.发展历程 3.企业荣誉 4.售后服务 5.公益与责任
      if (val === '1') {
        this.$router.push('/aboutUs')
      } else if (val === '2') {
        this.$router.push('/devHistory')
      }
      else if (val === '3') {
        this.$router.push('/honor')
      }
      else if (val === '4') {
        this.$router.push('/sales')
      }
      else if (val === '5') {
        this.$router.push('/duty')
      }
    },
    bannerClick (val) {
      if (val === 1) {
        this.$router.push('/product')
      } else if (val === 2) {
        this.$router.push('/product')
      } else if (val === 3) {
        this.$router.push('/innovation')
      }
    },
    handleResize () {
      this.fullWidth = document.documentElement.clientWidth;
      if (this.fullWidth < 500) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-text {
  text-align: center;
}
.container {
  height: 100%;
  width: 100%;

  .block {
    position: relative;
    width: 94%;
    padding: 0 3%;
    .banner-title {
      // line-height: 60px;
      color: #80005c;
      font-size: 20px;
      font-weight: 500;
    }
    #banner-bottom {
      position: absolute;
      bottom: 0;
      z-index: 9;
      height: 60px;
      width: 100%;
      background-color: #fff;
      opacity: 0.9;

      #col-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .flex {
        display: flex;
        align-items: center;
        cursor: pointer;
        .banner-icon {
          width: 36px;
          height: 28px;
          vertical-align: middle;
          padding-left: 3%;
        }
      }
    }
  }

  .img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .content {
    padding: 0 12%;
    .title-item {
      padding-right: 10px;
      color: #80005c;
    }
    .font-color {
      color: white;
    }
    .h3-size {
      font-size: 2em;
      font-weight: normal;
    }
    .content-title {
      display: flex;
      padding-bottom: 10px;
    }

    .news {
      padding: 20px 0;
      .icon {
        width: 100px;
        padding-bottom: 12%;
      }
      .ENwidth {
        width: 36%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .newsUrl {
        background: url("../assets/zhuye/newsImage.png") no-repeat;
        background-size: 100% 100%;
      }
      .aixinUrl {
        background: url("../assets/zhuye/M.png") no-repeat;
        background-size: 100% 100%;
      }
      .enBgc {
        background: url("../assets/EN/ENbgc.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 500px !important;
      }
      #love {
        height: 280px;
      }
      .news-content {
        height: 323px;
        .news-span {
          padding-top: 1%;
          font-size: 20px;
        }
      }
    }

    .now-future {
      .now-content {
        display: flex;
        .detail {
          color: #80005c;
          font-size: 14px;
        }
        .now-left {
          width: 50%;
          height: 320px;
        }
        .aboutUrl {
          background: url("../assets/zhuye/aboutUs.png") no-repeat;
          background-size: 100% 100%;
        }
        .HistoryUrl {
          background: url("../assets/zhuye/devHistory.png") no-repeat;
          background-size: 100% 100%;
        }
        .qiyeUrl {
          background: url("../assets/zhuye/lou.png") no-repeat;
          background-size: 100% 100%;
        }
        .salesUrl {
          background: url("../assets/zhuye/afSales.png") no-repeat;
          background-size: 100% 100%;
        }
        .now-right {
          width: 50%;
          height: 320px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          padding: 0px 12%;
          box-sizing: border-box;
        }
        .now-span {
          padding: 5% 0;
        }
      }
    }
  }
}
.detailClick {
  cursor: pointer;
  padding-top: 1%;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

@media only screen and (max-width: 500px) {
  .container {
    .block {
      width: 100%;
      padding: 0;
      #banner-bottom {
        background-color: #80005c;
        color: #fff;
        #col-flex {
          .flex {
            .banner-title {
              font-size: 12px;
              color: #fff;
              padding-bottom: 4%;
            }
            #left-icon {
              content: url("../assets/M/pig2.png");
              width: 20px;
              height: 20px;
            }
            #center-icon {
              content: url("../assets/M/Buser.png");
              width: 20px;
              height: 20px;
            }
            #right-icon {
              content: url("../assets/M/Byaoshui.png");
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .content {
      padding: 0 3%;
      .news {
        .ENwidth {
          width: 85%;
          .icon {
            padding-bottom: 5%;
          }
        }
        .enBgc {
          height: 352px !important;
        }
        .news-content {
          height: 195px;
          .h3-size {
            font-size: 1.5em;
          }
          .news-span {
            font-size: 16px;
          }
        }
      }
      #bottom-n {
        #love {
          .h3-size {
            font-size: 22px;
          }
          .news-span {
            font-size: 12px;
          }
        }
      }
      .now-future {
        .now-content {
          #left {
            height: 190px !important;
          }
          #right {
            padding: 0 5%;
            height: 195px;
            .now-span {
              font-size: 12px;
            }
            .h3-size {
              font-size: 1.5em;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .container {
    .block {
      .flex {
        flex-direction: column;
        align-items: center;
        #banner-bottom {
          background-color: #80005c;
          color: #fff;
          .banner-title {
            font-size: 12px;
            color: white;
          }
          .flex {
            #left-icon {
              content: url("../assets/M/pig2.png");
              width: 20px;
              height: 20px;
            }
            #center-icon {
              content: url("../assets/M/Buser.png");
              width: 20px;
              height: 20px;
            }
            #right-icon {
              content: url("../assets/M/Byaoshui.png");
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .content {
      padding: 0 3%;
      .news {
        .news-content {
          height: 200px;
          .h3-size {
            font-size: 1.5em;
          }
          .news-span {
            font-size: 16px;
          }
        }
      }
      .now-future {
        .now-content {
          #left {
            height: 200px;
          }
          #right {
            padding: 0 5%;
            height: 200px;
            .h3-size {
              font-size: 1.5em;
            }
            .now-span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    .block {
      .flex {
        flex-direction: column;
        align-items: center;
      }
      #banner-bottom {
        background-color: #80005c;
        color: #fff;
        .banner-title {
          font-size: 12px;
          color: white;
        }
        .flex {
          #left-icon {
            content: url("../assets/M/pig2.png");
            width: 20px;
            height: 20px;
          }
          #center-icon {
            content: url("../assets/M/Buser.png");
            width: 20px;
            height: 20px;
          }
          #right-icon {
            content: url("../assets/M/Byaoshui.png");
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .content {
      padding: 0 3%;
      .news {
        .news-content {
          .h3-size {
            font-size: 1.5em;
          }
          .news-span {
            font-size: 16px;
          }
          .detailClick {
            font-size: 12px;
            padding: 2% 0;
          }
        }
      }
      .now-future {
        .now-content {
          #left {
            height: 200px;
          }
          #right {
            padding: 0 5%;
            height: 200px;
            .h3-size {
              font-size: 1.5em;
            }
            .now-span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .container .content .news .news-content,
  .container .content .news .news-content#love {
    height: 200px;
  }
}
@media only screen and (max-width: 1400px) {
  .container {
    .block {
      #banner-bottom {
        #col-flex {
          .banner-title {
            font-size: 16px;
            padding-right: 3%;
          }
          #left-icon,
          #center-icon,
          #right-icon {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}
</style>
